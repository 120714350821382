import React from "react";
import shortid from  "shortid";
const LimeFlightWebsiteSection4 = (props) => {
    return(
        <section className="section-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-22">
                        <div className="image-block">
                            <div className="row">
                                {props.imageslist?.map((object, i) => {
                                    return (
                                        <div className="col-lg-12" key={shortid.generate()}>
                                            <div className="item">
                                                <img
                                                    src={object.image1x?.sourceUrl}
                                                    srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                    width={object.image1x?.width}
                                                    alt={object.image1x?.altText}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </section>
    )
}

export default LimeFlightWebsiteSection4;