import * as React from "react"

const LimeFlightWebsiteHeroSection = (props) => {
    return (
        <section className="page-header-section case-study-hero-section limeflight-website-hero-section p-b-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title-block">
                            <p className="small section-description text-white">{props.preTitle}</p>
                            <h1 className="h1 large section-title extra-spacing text-white">{props.mainTitle}</h1> 
                        </div>
                    </div>
                </div>
                <div className="hero-unit-1-xl-screen">
                    <img
                        src="https://backend.decojent.com/wp-content/uploads/2023/01/Hero_unit_2.webp"
                        alt="Hero Image"
                        width={100}
                    />
                </div>
                <div className="image-block">
                    <img
                        src={props.image1x?.sourceUrl}
                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                        width={props.image1x?.width}
                        alt={props.image1x?.altText}
                    />
                </div>
                <div className="mobile-image-block">
                    <img
                        src={props.mobileImage?.sourceUrl}
                        width={props.mobileImage?.width}
                        alt={props.mobileImage?.altText}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-11"></div>
                <div className="col-lg-13">
                    <div className="hero-unit-2-xl-screen">
                        <img
                            src="https://backend.decojent.com/wp-content/uploads/2023/01/Hero_Unit_1.webp"
                            alt="Hero Image"
                            width={100}
                        />
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default LimeFlightWebsiteHeroSection