import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import LimeFlightWebsiteHeroSection from '../sections/caseStudies/limeFlightWebsite/LimeFlightWebsiteHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import LimeFlightWebsiteSection1 from '../sections/caseStudies/limeFlightWebsite/LimeFlightWebsiteSection1';
import LimeFlightWebsiteSection2 from '../sections/caseStudies/limeFlightWebsite/LimeFlightWebsiteSection2';
import LimeFlightWebsiteSection3 from '../sections/caseStudies/limeFlightWebsite/LimeFlightWebsiteSection3';
import LimeFlightWebsiteSection4 from '../sections/caseStudies/limeFlightWebsite/LimeFlightWebsiteSection4';
import LimeFlightWebsiteSection5 from '../sections/caseStudies/limeFlightWebsite/LimeFlightWebsiteSection5';
import LimeFlightWebsiteSection6 from '../sections/caseStudies/limeFlightWebsite/LimeFlightWebsiteSection6';

const LimeFlightWebsite = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudyLimeFlightWebsitePageACF;
  console.log(mainData);
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  return (
    <Layout headerClass="white-header">
      <Seo title="LimeFlight" />
      <div className={"limeflight-website-case-study page-wrapper " + bgTransition} id="limeflight-website-casestudy-page-wrapper" ref={sectionRef}>
        <LimeFlightWebsiteHeroSection
          preTitle={parse(mainData.limeflightWebsiteHeroSectionPretitle)}
          mainTitle={parse(mainData.limeflightWebsiteHeroSectionTitle)}
          image1x={
            {
              sourceUrl: mainData.limeflightWebsiteHeroSectionImage1x.sourceUrl,
              altText: mainData.limeflightWebsiteHeroSectionImage1x.altText,
              width: mainData.limeflightWebsiteHeroSectionImage1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.limeflightWebsiteHeroSectionImage2x.sourceUrl,
              altText: mainData.limeflightWebsiteHeroSectionImage2x.altText,
              width: mainData.limeflightWebsiteHeroSectionImage2x.width,
            }
          }
          mobileImage={
              {
                sourceUrl: mainData.limeflightWebsiteHeroSectionMobileImage.sourceUrl,
                altText: mainData.limeflightWebsiteHeroSectionMobileImage.altText,
                width: mainData.limeflightWebsiteHeroSectionMobileImage.width,
              }
          }
        />
        <ChallengesSection 
          companyInfoList={mainData.limeflightWebsiteChallengeSectionCompanyInfoList}
          challengesList={mainData.limeflightWebsiteChallengeSectionChallangesList}
        />
        <LimeFlightWebsiteSection1 
          mainTitle={parse(mainData.limeflightWebsiteSection1Title)}
          mainDescription={parse(mainData.limeflightWebsiteSection1Description)}
          imageslist={mainData.limeflightWebsiteSection1ImageList}
        />
        <LimeFlightWebsiteSection2 
          mainTitle={parse(mainData.limeflightWebsiteSection2Title)}
          mainDescription={parse(mainData.limeflightWebsiteSection2Description)}
          image11x={
            {
              sourceUrl: mainData.limeflightWebsiteSection2Image11x.sourceUrl,
              altText: mainData.limeflightWebsiteSection2Image11x.altText,
              width: mainData.limeflightWebsiteSection2Image11x.width,
            }
          }
          image12x={
            {
              sourceUrl: mainData.limeflightWebsiteSection2Image12x.sourceUrl,
              altText: mainData.limeflightWebsiteSection2Image12x.altText,
              width: mainData.limeflightWebsiteSection2Image12x.width,
            }
          }
          image21x={
            {
              sourceUrl: mainData.limeflightWebsiteSection2Image21x.sourceUrl,
              altText: mainData.limeflightWebsiteSection2Image21x.altText,
              width: mainData.limeflightWebsiteSection2Image21x.width,
            }
          }
          image22x={
            {
              sourceUrl: mainData.limeflightWebsiteSection2Image22x.sourceUrl,
              altText: mainData.limeflightWebsiteSection2Image22x.altText,
              width: mainData.limeflightWebsiteSection2Image22x.width,
            }
          }
          imageslist={mainData.limeflightWebsiteSection2ImageList}
        />
        <LimeFlightWebsiteSection3 
          mainTitle={parse(mainData.limeflightWebsiteSection3Title)}
          image1x={
            {
              sourceUrl: mainData.limeflightWebsiteSection3Image1x.sourceUrl,
              altText: mainData.limeflightWebsiteSection3Image1x.altText,
              width: mainData.limeflightWebsiteSection3Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.limeflightWebsiteSection3Image2x.sourceUrl,
              altText: mainData.limeflightWebsiteSection3Image2x.altText,
              width: mainData.limeflightWebsiteSection3Image2x.width,
            }
          }
        />
        <LimeFlightWebsiteSection4 
          imageslist={mainData.limeflightWebsiteSection4ImageList}
        />
        <LimeFlightWebsiteSection5
          mainTitle={parse(mainData.limeflightWebsiteSection5Title)} 
          imageslist={mainData.limeflightWebsiteSection5ImageList}
        />
        <LimeFlightWebsiteSection6 
           mainTitle={parse(mainData.limeflightWebsiteSection6Title)}
           image1x={
              {
                sourceUrl: mainData.limeflightWebsiteSection6Image1x.sourceUrl,
                altText: mainData.limeflightWebsiteSection6Image1x.altText,
                width: mainData.limeflightWebsiteSection6Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.limeflightWebsiteSection6Image2x.sourceUrl,
                altText: mainData.limeflightWebsiteSection6Image2x.altText,
                width: mainData.limeflightWebsiteSection6Image2x.width,
              }
            } 
        />
      </div>
    </Layout>
  )
}



export const LimeFlightWebsiteQuery = graphql`

{
  allWpPage(filter: {slug: {eq: "limeflight-website"}}) {
      nodes {
        caseStudyLimeFlightWebsitePageACF {
            limeflightWebsiteHeroSectionPretitle
            limeflightWebsiteHeroSectionTitle
            limeflightWebsiteHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteHeroSectionMobileImage {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteChallengeSectionCompanyInfoList {
              title
              description
            }
            limeflightWebsiteChallengeSectionChallangesList {
              title
              description
            }
            limeflightWebsiteSection1Title
            limeflightWebsiteSection1Description
            limeflightWebsiteSection1ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            limeflightWebsiteSection2Title
            limeflightWebsiteSection2Description
            limeflightWebsiteSection2Image11x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteSection2Image12x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteSection2Image21x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteSection2Image22x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteSection2ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            limeflightWebsiteSection3Title
            limeflightWebsiteSection3Image1x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteSection3Image2x {
              altText
              sourceUrl
              width
            }

            limeflightWebsiteSection4ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }

            limeflightWebsiteSection5Title
            limeflightWebsiteSection5ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }

            limeflightWebsiteSection6Title
            limeflightWebsiteSection6Image1x {
              altText
              sourceUrl
              width
            }
            limeflightWebsiteSection6Image2x {
              altText
              sourceUrl
              width
            }
          }
      }
    }
}

`

export default LimeFlightWebsite