import React from "react";
import shortid from  "shortid";
const LimeFlightWebsiteSection2 = (props) => {
    return(
        <section className="section-2">
            <div className="container">
                <div className="title-block">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-18">
                            <h2 className="h1 section-title normal-spacing">{props.mainTitle}</h2>
                            <p className="section-description extra-spacing">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
                <div className="image-block-1">
                    <img
                        src={props.image11x?.sourceUrl}
                        srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                        width={props.image11x?.width}
                        alt={props.image11x?.altText}
                    />
                </div>
            </div>
            <div className="image-block-2">
                <div className="container">
                    <div className="image-block">
                        <img
                            src={props.image21x?.sourceUrl}
                            srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                            width={props.image21x?.width}
                            alt={props.image21x?.altText}
                        />
                    </div>
                </div>
            </div>
            <div className="image-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-20">
                            <div className="image-block">
                                {props.imageslist?.map((object, i) => {
                                    return (
                                        <div className="item" key={shortid.generate()}>
                                            <img
                                                src={object.image1x?.sourceUrl}
                                                srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                width={object.image1x?.width}
                                                alt={object.image1x?.altText}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LimeFlightWebsiteSection2;